import Vue from 'vue'
import VueRouter from 'vue-router'
// import { canNavigate } from '@/libs/acl/routeProtection'
import { currentUserRole, isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect:'/dashboard'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: () => import('@/views/campaigns/index.vue'),
      meta: {
        pageTitle: 'Campaigns',
        breadcrumb: [
          {
            text: 'Campaigns',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/contents',
      name: 'contents',
      component: () => import('@/views/contents/index.vue'),
      meta: {
        pageTitle: 'Contents',
        breadcrumb: [
          {
            text: 'Contents',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/user-profile',
      name: 'user-profile',
      component: () => import('@/views/user-profile/userProfile.vue'),
      meta: {
        pageTitle: 'User Profile',
        breadcrumb: [
          {
            text: 'User Profile',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/setups',
      name: 'setups',
      component: () => import('@/views/setups/index.vue'),
      meta: {
        pageTitle: 'Screens',
        breadcrumb: [
          {
            text: 'Screens',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/partners/index.vue'),
      meta: {
        pageTitle: 'Partners',
        breadcrumb: [
          {
            text: 'Partners',
            active: true,
          },
        ],
        isAdmin: false,
        auth: true,
      },
    },
    {
      path: '/partners/create',
      name: 'partnersCreate',
      component: () => import('@/views/partners/create.vue'),
      meta: {
        pageTitle: 'Partners Register',
        breadcrumb: [
          {
            text: 'Partners Register',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/segments',
      name: 'segments',
      component: () => import('@/views/segments/index.vue'),
      meta: {
        pageTitle: 'Segments',
        breadcrumb: [
          {
            text: 'Segments',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/cities',
      name: 'cities',
      component: () => import('@/views/cities/index.vue'),
      meta: {
        pageTitle: 'Cities',
        breadcrumb: [
          {
            text: 'Cities',
            active: true,
          },
        ],
        isAdmin: true,
        auth: true,
      },
    },
    {
      path: '/addLocations',
      name: 'addLocations',
      component: () => import('@/views/addLocations/index.vue'),
      meta: {
        pageTitle: 'Add Locations',
        breadcrumb: [
          {
            text: 'add Locations',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
        guest: true,
        resource: 'Auth',
        redirectIfLoggedIn: true,
        auth: false,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userRole = currentUserRole()
  if (to.matched.some(record => record.meta.auth)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if ((to.meta.redirectIfLoggedIn && isLoggedIn)) {
    // const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // const userData = getUserData()
    next('/')
  }

  if (isLoggedIn && to.meta.isAdmin && userRole !== 'Admin') {
    next('/')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
